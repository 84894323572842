/* eslint-disable @typescript-eslint/naming-convention */
import { environment } from 'environments/environment';
import { DOCUMENTS } from './core/constants/api-constant';

export const GlobalVariable = Object.freeze({
    BASE_URL: environment.URL,
});

export const ApiUrls = Object.freeze({
    USER_LOGIN: `${GlobalVariable.BASE_URL}/user/user-login`,
    ACTIVE_DIRECTORY_LOGIN: `${GlobalVariable.BASE_URL}/user/azure-ad-login`,
    USER_DATA_PAYLOAD: `${GlobalVariable.BASE_URL}/user/user-data-payload`,
    USER_ROLE_BY_DEPT_ID: `${GlobalVariable.BASE_URL}/user/user-role-data`,
    UNIQUE_USER_ROLE_BY_DEPT_ID: `${GlobalVariable.BASE_URL}/user/unique-user-role-data`,
    UNIQUE_USER_ROLE: `${GlobalVariable.BASE_URL}/user/unique-user-role-data`,
    UPLOAD_PROFILE_PIC: `${GlobalVariable.BASE_URL}/api/buyer/file/profilepic`,
    UPDATE_PROFILE: `${GlobalVariable.BASE_URL}/user/update-user`,
    UPDATE_USER_PROFILE: `${GlobalVariable.BASE_URL}/user/update-user-profile`,

    USER_ROLE_BY_DEPARTMENT_ID: `${GlobalVariable.BASE_URL}/unique-user-role-data`,

    USER_PROFILE_AUDIT_HISTORY: `${GlobalVariable.BASE_URL}/api/buyer/user-history/history`,

    // Supplier
    SUPPLIER_LIST: `${GlobalVariable.BASE_URL}/api/buyer/supplier/supplier-list`,
    EXPORT_SUPPLIERS_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/supplier/export-suppliers`,
    DOWNLOAD_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/supplier/export-supplier-template`,
    SINGLE_SUPPLIER: `${GlobalVariable.BASE_URL}/api/buyer/supplier/single-supplier`,
    UPLOAD_SUPPLIERS_LIST: `${GlobalVariable.BASE_URL}/api/buyer/supplier/upload-suppliers`,
    ONBOARD_SUPPLIERS: `${GlobalVariable.BASE_URL}/api/buyer/supplier/onboard-suppliers`,
    UPDATE_SUPPLIER: `${GlobalVariable.BASE_URL}/api/buyer/supplier/update-supplier`,
    INDUSTRY_CATEGORY_LIST: `${GlobalVariable.BASE_URL}/api/buyer/supplier/industry-category-list`,
    INDUSTRY_CATEGORY_DROPDOWN_LIST: `${GlobalVariable.BASE_URL}/api/buyer/supplier/suppliersCategoryDrop`,
    UNLOCK_SUPPLIER_PROFILE_BY_VENDORCODE : `${GlobalVariable.BASE_URL}/api/buyer/supplier/unlock-supplier-profile-by-vendorcode`,

    // Supplier Tag
    SUPPLIER_TAG_LIST: `${GlobalVariable.BASE_URL}/api/buyer/supplier/supplier-tag/supplier-tag-list`,
    SUPPLIER_TAG_HIGH_COUNT_PURCHASE_ORG: `${GlobalVariable.BASE_URL}/api/buyer/supplier/supplier-tag/highest-count-by-purchaseOrg`,
    SUPPLIER_TAG_CREATE: `${GlobalVariable.BASE_URL}/api/buyer/supplier/supplier-tag/create`,
    SUPPLIER_TAG_UPDATE: `${GlobalVariable.BASE_URL}/api/buyer/supplier/supplierTag-update`,
    SUPPLIER_TAG_GET_SINGLE: `${GlobalVariable.BASE_URL}/api/buyer/supplier/getSingleSupplierTag`,
    SUPPLIER_TAG_PREVIEW:`${GlobalVariable.BASE_URL}/api/buyer/supplier/getSupplierTagPreview`,

    // Supplier Role
    SUPPLIER_ROLE_LIST: '/api/buyer/supplier/supplier-role-list',
    CREATE_SUPPLIER_ROLE: '/api/buyer/supplier/create-supplier-role',
    UPDATE_SUPPLIER_ROLE: '/api/buyer/supplier/update-supplier-role',
    SINGLE_SUPPLIER_ROLE: '/api/buyer/supplier/single-supplier-role',
    EXPORT_SUPPLIER_ROLE: `${GlobalVariable.BASE_URL}/api/buyer/supplier/export-supplier-roles`,
    SUPPLIER_TAGS_LIST: `${GlobalVariable.BASE_URL}/api/buyer/supplier/supplier-tags-list`,

    // Supplier-Module-Access-Rights
    SUPPLIER_MODULE_LIST: '/api/buyer/supplier/get-module-access-rights',
    SUPPLIER_MODULE_ACCESS_RIGHTS_DROPDOWN_LIST: '/api/buyer/supplier/get-all-module-access-rights',
    CREATE_SUPPLIER_MODULE: '/api/buyer/supplier/create-module-access-rights',
    UPDATE_SUPPLIER_MODULE: '/api/buyer/supplier/update-module-access-rights',
    SINGLE_SUPPLIER_MODULE: '/api/buyer/supplier/get-single-module-access-rights',
    SUPPLIER_MODULE_LISTING: '/api/buyer/supplier/access-rights',
    EXPORT_SUPPLIER_MODULE: `${GlobalVariable.BASE_URL}/api/buyer/supplier/export-supplier-module-access-rights`,

    // User-Access-Rights
    USER_GET_LIST_ACCESS_RIGHTS: `${GlobalVariable.BASE_URL}/user/get-user-module-access-rights-list`,
    USER_GET_ACCESS_RIGHTS: `${GlobalVariable.BASE_URL}/user/get-access-rights`,
    USER_CREATE_ACCESS_RIGHTS: `${GlobalVariable.BASE_URL}/user/create-user-module-access-rights`,
    USER_UPDATE_ACCESS_RIGHTS: `${GlobalVariable.BASE_URL}/user/update-user-module-access-rights`,
    USER_GET_VIEW_ACCESS_RIGHTS: `${GlobalVariable.BASE_URL}/user/getUserAccessById`,
    USER_EXPORT_ACCESS_RIGHTS: `${GlobalVariable.BASE_URL}/user/export-user-module-access-rights-list`,

    // User-Role
    USER_ROLE_LIST: `${GlobalVariable.BASE_URL}/user/user-role-list`,
    CREATE_USER_ROLE: `${GlobalVariable.BASE_URL}/user/create-user-role`,
    UPDATE_USER_ROLE: `${GlobalVariable.BASE_URL}/user/update-user-role`,
    SINGLE_USER_ROLE: `${GlobalVariable.BASE_URL}/user/single-user-role-list`,
    EXPORT_USER_ROLES: `${GlobalVariable.BASE_URL}/user/export-user-roles`,

    //User-List
    USER_LIST: `${GlobalVariable.BASE_URL}/user/user-list`,
    EXPORT_USERS: `${GlobalVariable.BASE_URL}/user/export-users`,
    UPDATE_USERS: `${GlobalVariable.BASE_URL}/user/update-user`,
    SINGLE_USER: `${GlobalVariable.BASE_URL}/user/get-user`,
    USER_DEPARTMENT: `${GlobalVariable.BASE_URL}/api/buyer/user/departmentby-email`,

    //User-Module-Access-Rights
    USER_MODULE_LIST: `${GlobalVariable.BASE_URL}/user/get-module-access-rights`,
    CREATE_USER_MODULE: `${GlobalVariable.BASE_URL}/user/create-module-access-rights`,
    UPDATE_USER_MODULE: `${GlobalVariable.BASE_URL}/user/update-module-access-rights`,
    EXPORT_USER_MODULE: `${GlobalVariable.BASE_URL}/user/export-user-module-access-rights`,
    SINGLE_USER_MODULE: `${GlobalVariable.BASE_URL}/user/single-user-module-access-right`,

    // Department
    USER_DEPARTMENT_LIST: `${GlobalVariable.BASE_URL}/user/active-department-list`,
    DEPARTMENT_LIST: `${GlobalVariable.BASE_URL}/user/department-list`,
    DEPARTMENT_CREATE: `${GlobalVariable.BASE_URL}/user/create-department`,
    DEPARTMENT_UPDATE: `${GlobalVariable.BASE_URL}/user/update-department`,
    SINGLE_DEPARTMNET: `${GlobalVariable.BASE_URL}/user/get-department`,
    EXPORT_DEPARTMENT: `${GlobalVariable.BASE_URL}/user/export-department`,

    //User-Purchasing-Org-List
    USER_PURCHASING_LIST: `${GlobalVariable.BASE_URL}/user/purchasing-org-list`,
    SINGLE_PURCHASING_LIST: `${GlobalVariable.BASE_URL}/user/single-purchasing-org`,

    //User-Pre-Pr-List
    USER_PRE_PR_LIST: `${GlobalVariable.BASE_URL}/user/pre-pr-list`,
    SINGLE_PRE_PR_LIST: `${GlobalVariable.BASE_URL}/user/single-pre-pr`,

    // SINGLE_PRE_PR_LIST;

    //User-PR-List
    USER_PR_LIST: `${GlobalVariable.BASE_URL}/user/pr-list`,
    SINGLE_PR_LIST: `${GlobalVariable.BASE_URL}/user/single-pr`,

    //User-RFx-List
    USER_RFX_LIST: `${GlobalVariable.BASE_URL}/user/rfx-list`,
    SINGLE_RFX_LIST: `${GlobalVariable.BASE_URL}/user/single-rfx`,

    //User-Role-Based-On-Deparmenent
    USER_ROLE_BY_DEPT: `${GlobalVariable.BASE_URL}/user/user-role-data`,

    // PR Template
    PR_TEMPLATE_LIST: `${GlobalVariable.BASE_URL}/api/buyer/prTemplate/list`,
    POST_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/prTemplate/add`,
    PUT_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/prTemplate/update`,
    EXPORT_PR_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/prTemplate/export-pr-template-list`,
    DOWNLOAD_PR_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/prTemplate/download-sourcing-template-format`,
    IMPORT_PR_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/prTemplate/import-pr-template`,
    PR_TEMPLATE_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/prTemplate/get-by-id`,

    // PR
    DOCUMENT_UPLOAD: `${GlobalVariable.BASE_URL + DOCUMENTS.DOCUMENT_UPLOAD}`,
    DELETE_BUYER_DOCS: `${GlobalVariable.BASE_URL}/api/buyer/pr/document`,
    GET_PR_LIST_USER_ACCESS: `${GlobalVariable.BASE_URL}/api/buyer/pr/pr-list/departmental-viewer-pr-list`,
    // GET_PR_LIST: `${GlobalVariable.BASE_URL}/api/buyer/pr/list`,
    GET_PR_LIST: `${GlobalVariable.BASE_URL}/api/buyer/pr/pr-list/purcahseRequistion-user-access`,
    GET_PR_ERROR_LIST: `${GlobalVariable.BASE_URL}/api/buyer/pr/prError/list`,
    DOWNLOAD_PR_LIST_PDF: `${GlobalVariable.BASE_URL}/api/buyer/pr/exportPDF`,
    // DOWNLOAD_PR_LIST_EXCEL: `${GlobalVariable.BASE_URL}/api/buyer/pr/exportExcel`,
    DOWNLOAD_PR_LIST_EXCEL: `${GlobalVariable.BASE_URL}/api/buyer/pr/pr-list/exportPrListExcelUserAccess`,
    PR_CREATE: `${GlobalVariable.BASE_URL}/api/buyer/pr/create`,
    GET_PR_AUDITHISTORY: `${GlobalVariable.BASE_URL}/api/buyer/pr/auditHistory`,
    CANCEL_PR_SUMMARY: `${GlobalVariable.BASE_URL}/api/buyer/pr/cancelPR`,
    SUBMIT_PR_FOR_APPROVAL: `${GlobalVariable.BASE_URL}/api/buyer/pr/submitPRForApproval`,
    SUBMIT_CAPEX_FOR_APPROVAL: `${GlobalVariable.BASE_URL}/api/buyer/pr/submitECAPEXForApproval/`,
    CREATE_PR_FROM_CAPEX: `${GlobalVariable.BASE_URL}/api/buyer/pr/createPRFromECAPEx/`,
    GET_PR_AUDIT_HISTORY: `${GlobalVariable.BASE_URL}/api/buyer/pr/auditHistory`,
    GET_PR_APPROVER_COMMENTS: `${GlobalVariable.BASE_URL}/api/buyer/pr/approverComments`,
    PR_ACCOUNT_ASSIGNMENT_CODE_DETAILS: `${GlobalVariable.BASE_URL}/api/buyer/master`,
    DOWNLOAD_PR_PDF: `${GlobalVariable.BASE_URL}/api/buyer/pr/downloadPDF`,
    DOWNLOAD_ECAPEX_PDF: `${GlobalVariable.BASE_URL}/api/buyer/pr/downloadECAPEXPDF`,
    DELETE_PR_DOCUMENT_BY_NAME: `${GlobalVariable.BASE_URL + DOCUMENTS.DOCUMENT_DELETE}`,
    PR_TEMPLATE_NAME_LIST_BY_TYPE: `${GlobalVariable.BASE_URL}/api/buyer/prTemplate/pr-template-name-list-by-type`,
    PENDING_ECAPEX_PR_CREATION_COUNT_BY_PURCHASE_ORG: `${GlobalVariable.BASE_URL}/api/buyer/pr/pending-ecapex-pr-creation-count-by-purchasing-org`,

    DUE_COUNT_BY_PURCHASE_ORG: `${GlobalVariable.BASE_URL}/api/buyer/pr/due-pr-count-by-purchasing-org`,
    DRAFT_COUNT_BY_PURCHASE_ORG: `${GlobalVariable.BASE_URL}/api/buyer/pr/draft-pr-count-by-purchasing-org`,
    PENDING_COUNT_BY_PURCHASE_ORG: `${GlobalVariable.BASE_URL}/api/buyer/pr/pending-pr-count-by-purchasing-org`,

    DUE_COUNT_BY_PURCHASE_ORG_ECAPEX: `${GlobalVariable.BASE_URL}/api/buyer/pr/due-ecapex-count-by-purchasing-org`,
    DRAFT_COUNT_BY_PURCHASE_ORG_ECAPEX: `${GlobalVariable.BASE_URL}/api/buyer/pr/draft-ecapex-count-by-purchasing-org`,
    PENDING_COUNT_BY_PURCHASE_ORG_ECAPEX: `${GlobalVariable.BASE_URL}/api/buyer/pr/pending-ecapex-count-by-purchasing-org`,

    GRA_DUE_COUNT_BY_PURCHASE_ORG: `${GlobalVariable.BASE_URL}/api/buyer/gra-buyer/due-gra-count-by-purchasing-org`,
    GRA_DRAFT_COUNT_BY_PURCHASE_ORG: `${GlobalVariable.BASE_URL}/api/buyer/gra-buyer/draft-gra-count-by-purchasing-org`,
    GRA_PENDING_COUNT_BY_PURCHASE_ORG: `${GlobalVariable.BASE_URL}/api/buyer/gra-buyer/pending-gra-count-by-purchasing-org`,

    PR_TEMPLATE_DETAILS_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/prTemplate/get-by-id?id={0}`,
    GET_PR_DETAILS: `${GlobalVariable.BASE_URL}/api/buyer/pr/{0}`,
    GET_PR_SUMMARY: `${GlobalVariable.BASE_URL}/api/buyer/pr`,
    PR_UPDATE: `${GlobalVariable.BASE_URL}/api/buyer/pr/update`,
    ECAPEX_APPROVAL: `${GlobalVariable.BASE_URL}/api/buyer/pr/ecapexApproval/`,
    PR_APPROVAL: `${GlobalVariable.BASE_URL}/api/buyer/pr/prApproval/`,
    GET_APPROVAL_ROUTE_LEVELS: `${GlobalVariable.BASE_URL}/api/buyer/pr/getApprovalRouteLevels/{0}`,
    ESCALATE_PR: `${GlobalVariable.BASE_URL}/api/buyer/pr/escalatePR/{0}/{1}`,
    ESCALATE_CAPEX_PR: `${GlobalVariable.BASE_URL}/api/buyer/pr/escalateECAPEX/{0}/{1}`,
    PR_UPDATE_FAD_NO: `${GlobalVariable.BASE_URL}/api/buyer/pr/{0}/changeFADNo`,
    PR_UPDATE_BUDGET_REF_CODE: `${GlobalVariable.BASE_URL}/api/buyer/pr/{0}/changeBudgetRefCode`,
    REJECT_PR_ERROR: `${GlobalVariable.BASE_URL}/api/buyer/pr/prReject/{0}`,
    TRIGER_PO: `${GlobalVariable.BASE_URL}/api/buyer/pr/triggerPO/{0}`,
    EXPORT_PR_ERROR_REPORT: `${GlobalVariable.BASE_URL}/api/buyer/pr/prError/exportExcel`,
    BY_PR_ID: `${GlobalVariable.BASE_URL}/api/buyer/po/byPRId`,
    ECAPEX_BY_ECAPEX_ID: `${GlobalVariable.BASE_URL}/api/buyer/pr/byECAPExId`,
    PR_PURCHASE_ORG_LIST: `${GlobalVariable.BASE_URL}/user/purchaseOrg-list-by-userId`,
    IS_SUPPLIER_VALID_WHEN_APPROVING: `${GlobalVariable.BASE_URL}/api/buyer/pr/isSupplierValidWhenApproving/`,
    IS_SUPPLIER_VALID: `${GlobalVariable.BASE_URL}/api/buyer/pr/isSupplierValid/`,
    PR_UPLOAD_OTHER_DOCUMENTS: `${GlobalVariable.BASE_URL}/api/buyer/pr/uploadOtherDocuments/`,
    CHANGE_BUDGET_FOR_BOQ: `${GlobalVariable.BASE_URL}/api/buyer/pr/{0}/changeBudgetForBOQ`,
    PR_UPDATE_BOQ_ITEMS: `${GlobalVariable.BASE_URL}/api/buyer/pr/{0}/updateBoq`,

    // Pre-PR Template
    PRE_PR_TEMPLATE_LIST: `${GlobalVariable.BASE_URL}/api/buyer/preprTemplate/list`,
    CREATE_PRE_PR_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/preprTemplate/create`,
    PRE_PR_TEMPLATE_UPDATE: `${GlobalVariable.BASE_URL}/api/buyer/preprTemplate`,
    PRE_PR_TEMPLATE_LIST_BY_USER: `${GlobalVariable.BASE_URL}/api/buyer/preprTemplate/listByUser`,
    PRE_PR_TEMPLATE_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/preprTemplate`,
    DOWNLOAD_PRE_PR_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/preprTemplate/export-prepr-template-format`,
    EXPORT_PRE_PR_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/preprTemplate/export-prepr-template-by-id`,
    IMPORT_PRE_PR_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/preprTemplate/import-prepr-template`,
    USER_PRE_PR_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/preprTemplate/user-preprTemplatelist`,
    UPLOAD_PRE_PR_TEMPLATE_LIST: `${GlobalVariable.BASE_URL}/api/supplier/upload-supplier`,
    DEPARTMENT_LIST_BY_PURCHASING_ID: `${GlobalVariable.BASE_URL}/user/get-department-by-purchaseOrgID`,

    // PrePR
    PRE_PR_GET_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/prePr`,
    PRE_PR_CREATE: `${GlobalVariable.BASE_URL}/api/buyer/prePr/create`,
    PRE_PR_UPDATE: `${GlobalVariable.BASE_URL}/api/buyer/prePr`,
    PREPR_LIST: `${GlobalVariable.BASE_URL}/api/buyer/prePr/listByUser`,
    PENDING_PRE_PR_LIST: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/duePrePr`,
    PENDING_RFX_CREATION_PRE_PR_LIST: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/pendingRfxCreation`,
    SAMPLE_PRE_PR_BOQ: `${GlobalVariable.BASE_URL}/api/buyer/prePr/sample-boq`,
    EXPORT_PRE_PR_BOQ: `${GlobalVariable.BASE_URL}/api/buyer/prePr/export-boq`,
    UPLOAD_PRE_PR_BOQ: `${GlobalVariable.BASE_URL}/api/buyer/prePr/upload-boq`,
    EXPORT_PREPR_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/prePr/export-prepr-by-id`,
    // UPLOAD_PRE_PR_SUPPORTING_DOCS: `${GlobalVariable.BASE_URL}/api/buyer/documents/upload`,
    // UPDATE_PRE_PR_SUPPORTING_DOCS: `${GlobalVariable.BASE_URL}/api/buyer/prePr/supportingdocs`,
    // DELETE_PRE_PR_SUPPORTING_DOCS: `${GlobalVariable.BASE_URL}/api/buyer/documents/file/delete`,
    PRE_PR_APPROVE_STATUS_UPDATE: `${GlobalVariable.BASE_URL}/api/buyer/prePr/updateStatus`,
    PRE_PR_APPROVE: `${GlobalVariable.BASE_URL}/api/buyer/prePr/approve-prepr`,
    PRE_PR_REJECT: `${GlobalVariable.BASE_URL}/api/buyer/prePr/reject-prepr`,
    PRE_PR_ESCALATE: `${GlobalVariable.BASE_URL}/api/buyer/prePr/escalate-prepr`,
    DOWNLOAD_PRE_PR_PDF: `${GlobalVariable.BASE_URL}/api/buyer/prePr/pre_pr_summary-pdf`,
    COST_CENTER_LIST_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/master/cost-center-list/byCompanyCode`,
    PREPR_COPY_LIST: `${GlobalVariable.BASE_URL}/api/buyer/prePr/prepr-copyList`,
    EXPORT_PREPR_COPY: `${GlobalVariable.BASE_URL}/api/buyer/prePr/export-prepr-copy`,

    // PaymentRemittance
    PAY_REM_LIST: '/api/buyer/payment-remittance-buyer/payment-remittance-departmental-viewer',
    PAY_REM_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/payment-remittance-buyer/buyer`,
    EXPORT_PAY_REM: `${GlobalVariable.BASE_URL}/api/buyer/payment-remittance-buyer/buyer/export-list`,
    PAY_REM_PURCHASE_ORG: `${GlobalVariable.BASE_URL}/user/purchaseOrg-list-by-userId`,
    PAY_REM_PDF_DOWNLOAD: `${GlobalVariable.BASE_URL}/api/buyer/payment-remittance-buyer/buyer/summary`,

    // PaymentRemittanceSupplier
    PAY_REM_SUPPLIER_LIST: '/api/buyer/payment-remittance-buyer/supplier-list',
    PAY_REM_SUPPLIER_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/payment-remittance-buyer/supplier`,
    EXPORT_PAY_REM_SUPPLIER: `${GlobalVariable.BASE_URL}/api/buyer/payment-remittance-buyer/supplier/export-list`,
    PAY_REM_SUPLIER_PDF_DOWNLOAD: `${GlobalVariable.BASE_URL}/api/buyer/payment-remittance-buyer/supplier/summary`,

    //GRA
    GRA_LIST: '/api/buyer/gra-buyer/list/gra-list-user-access',
    GRA_DETAIL: `${GlobalVariable.BASE_URL}/api/buyer/gra-buyer`,
    EXPORT_GRA: `${GlobalVariable.BASE_URL}/api/buyer/gra-buyer/list/export-list-user-access`,
    GRA_PDF_DOWNLOAD: `${GlobalVariable.BASE_URL}/api/buyer/gra-buyer/summary`,

    //Buyer Contract PRice
    BUYER_CONTRACT_PRICE_LIST: `${GlobalVariable.BASE_URL}/api/buyer/contract-buyer/list`,
    BUYER_CONTRACT_PRICE_LIST_NEW: `${GlobalVariable.BASE_URL}/api/buyer/contract-buyer/list`,
    BUYER_CONTRACT_PRICE_EXPORT: `${GlobalVariable.BASE_URL}/api/buyer/contract-buyer/contract-price-export`,
    BUYER_CONTRACT_PRICE_PDF: `${GlobalVariable.BASE_URL}/api/buyer/contract-price-supplier/download-pdf`,
    BUYER_CONTRACT_PRICE_DETAIL: `${GlobalVariable.BASE_URL}/api/buyer/contract-buyer/buyers-detail`,
    CONTRACT_COUNT_BY_PURCHASEORG: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/contract-count-by-purchaseOrg`,

    // Invoice
    INVOICE_BUYER_LIST: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/invoice-list-user-access`,
    INVOICE_BUYER_LIST_PENDING: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/pendingInvoiceList`,
    INVOICE_BUYER_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/buyers-detail`,
    EXPORT_INVOICE_BUYER: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/export-buyers3way-list-user-access`,
    DOWNLOAD_INVOICE_PDF: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/supplier/pdf/generate`,
    GET_INVOICE_SUMMARY: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/supplier-summary`,

    //PIR
    PIR_LIST: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/buyers-list`,
    EXPORT_PIR: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/export-buyers3way-list`,
    PIR_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/buyers-detail`,

    // Masters
    PRE_PR_STATUS_LIST: `${GlobalVariable.BASE_URL}/api/buyer/prePr/status-list`,
    COST_CENTER_LIST: `${GlobalVariable.BASE_URL}/api/buyer/master/cost-center-list`,
    CURRENCY_LIST: `${GlobalVariable.BASE_URL}/api/buyer/master/currency-list`,
    PURCHASE_ORG_LIST: `${GlobalVariable.BASE_URL}/api/buyer/master/purchase-org-list`,
    DELIVERY_ADDRESS_LIST: `${GlobalVariable.BASE_URL}/api/buyer/master/address-list`,
    DELIVERY_ADDRESS_PREPR_LIST: `${GlobalVariable.BASE_URL}/api/buyer/master/address-list-special`,
    DEPARTMENTOPTION_LIST: `${GlobalVariable.BASE_URL}/user/active-department-list`,
    DEPARTMEN_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/master/department`,
    WARRANTY_LIST: `${GlobalVariable.BASE_URL}/api/buyer/master/warranty-list`,
    FLAT_SUPPLIER_LIST: `${GlobalVariable.BASE_URL}/api/buyer/flat/supplier-list`,
    UOM_LIST: `${GlobalVariable.BASE_URL}/api/buyer/master/uom-list`,

    // INTERNAL_ORDER_LIST: `${GlobalVariable.BASE_URL}/api/buyer/master/internalorder-list`,
    // ALL_USER_LIST: `${GlobalVariable.BASE_URL}/api/buyer/user/allUserList`,
    // PART_NUMBER_LIST: `${GlobalVariable.BASE_URL}/api/buyer/flat/part-number-list`,

    PART_NUMBER_LIST: `${GlobalVariable.BASE_URL}/api/buyer/flat/part-number-list`,
    ALL_USER_NEW_LIST: `${GlobalVariable.BASE_URL}/user/all-users`,
    INTERNAL_ORDER_LIST: `${GlobalVariable.BASE_URL}/api/buyer/master/internal-order-list`,
    ALL_USER_LIST: `${GlobalVariable.BASE_URL}/user/all-users`,

    //Password setting
    PASSWORD_SETTING: `${GlobalVariable.BASE_URL}/api/buyer/master/password-setting`,
    PASSWORD_SETTING_UPDATE: `${GlobalVariable.BASE_URL}/api/buyer/master/password-setting`,

    //PO
    // PO_LIST_FOR_USER: `${GlobalVariable.BASE_URL}/api/buyer/po/po-list-for-user`,
    PO_LIST_FOR_USER: `${GlobalVariable.BASE_URL}/api/buyer/po/po-list/po-list-user-access`,
    // PO_LIST_FOR_USER_EXPORT_EXCEL: `${GlobalVariable.BASE_URL}/api/buyer/po/po-list-for-user/exportExcel`,
    PO_LIST_FOR_USER_EXPORT_EXCEL: `${GlobalVariable.BASE_URL}/api/buyer/po/po-list/export-po-list-user-access`,
    PO_DETAILS_FOR_USER: `${GlobalVariable.BASE_URL}/api/buyer/po/byPONumber/{0}`,
    PO_PURCHASE_ORG_LIST: `${GlobalVariable.BASE_URL}/user/purchaseOrg-list-by-userId`,
    PO_DETAILS_FOR_USER_SUMMARY_PDF: `${GlobalVariable.BASE_URL}/api/buyer/po/summaryPDF/{0}`,
    PO_DETAILS_FOR_USER_DETAILS_PDF: `${GlobalVariable.BASE_URL}/api/buyer/po/detailsPDF/{0}`,
    PO_DETAILS_GRA_BY_PO_NUM: `${GlobalVariable.BASE_URL}/api/buyer/gra-buyer/by-ponumber/{0}`,
    PO_DETAILS_INVOICE_BY_PO_NUM: `${GlobalVariable.BASE_URL}/api/buyer/Supplier3wayInvoice/invoice-list-by-po-number/{0}`,
    PO_AUDIT_HISTORY_BY_PO_NUM: `${GlobalVariable.BASE_URL}/api/buyer/po/auditHistory/{0}`,
    CHECK_FOR_GRA_APPROVAL_WORKFLOW: `${GlobalVariable.BASE_URL}/api/buyer/po/check-for-gra-approval-workflow`,

    //DASHBOARD
    DASHBOARD_COUNT: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/buyer-dashboard-count`,
    DASHBOARD_DATA_COUNT: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/getTotalCounts`,
    DASHBOARD_DUE_TOTAL_COUNT: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/getDueTotalCount`,
    DASHBOARD_DRAFT_TOTAL_COUNT: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/getDraftTotalCount`,
    DASHBOARD_PENDING_TOTAL_COUNT: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/getPendingTotalCount`,
    DASHBOARD_ACTIVE_TOTAL_COUNT: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/getActiveTotalCount`,

    DASHBOARD_DUE_TASKS_PRE_PR: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/due-task-pre-pr`,
    DASHBOARD_DUE_TASKS_SOURCING_EVENT: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/due-task-sourcing-event`,
    DASHBOARD_DUE_TASKS_RESUME_SOURCING_EVENT: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/due-task-resume-sourcing-event`,
    DASHBOARD_DUE_TASKS_SOURCING_EVALUATION: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/due-task-sourcing-evaluation`,
    DASHBOARD_DUE_TASKS_PENDING_BUYER_EVALUATION: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/due-task-pending-Buyer-Evaluation`,
    DASHBOARD_DUE_TASKS_PENDING_REQUESTOR_EVALUATION: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/due-task-pending-Requestor-Evaluation`,
    DASHBOARD_DUE_TASKS_PENDING_PR_CREATION: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/due-task-pr-pending-creation`,
    DASHBOARD_TASKS_PRE_PR_LIST: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/draft-pre-pr-list`,
    DASHBOARD_PENDING_PRE_PR: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/pending-pre-pr`,
    DASHBOARD_SCHEDULE_TASK: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/schedule-meeting`,
    DASHBOARD_REQUEST_TRACKING: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/request-tracking`,
    DASHBOARD_BUYER_ACTIVE_EVENTS: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/buyer-active-events`,
    DASHBOARD_REQUEST_SUMMARY: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/request-summary-count`,
    DASHBOARD_Finished_Sourcing_Proposal: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/request-summary-finished-sourcing-proposal`,
    DASHBOARD_DUE_TASKS_SOURCING_PROPOSAL: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/due-task-sourcing-proposal`, // not created yet
    DASHBOARD_USER_ORG_LIST: `${GlobalVariable.BASE_URL}/user/purchaseOrg-list-by-userId`,
    DASHBOARD_DOWNLOAD_REQUEST_TRACKING_REPORT_EXCEL: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/export-request-tracking-report`,
    DASH_PR_PENDING_COUNT_BY_PURCHASE_ORG: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/draft-pending-pr-count-by-purchasing-org`,
    DASH_PO_PENDING_COUNT_BY_PURCHASE_ORG: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/po-count-by-purchasing-org`,
    DASHBOARD_SELF_MANAGEMENT_TRACKING: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/selfManagement-tracking`,
    DASHBOARD_SELF_MANAGEMENT_TRACKING_EXPORT: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/export-self-manage-tracking-list`,
    PURCHASE_ORG_LIST_HIGH_COUNT: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/highest-modulewise-count-by-purcahseOrg`,
    PURCHASE_ORG_LIST_HIGH_COUNT_BY_PO: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/po-count-by-purchaseOrg`,
    PURCHASE_ORG_LIST_HIGH_COUNT_BY_PR: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/pr-count-by-purchaseOrg`,
    PURCHASE_ORG_LIST_HIGH_COUNT_BY_ECAPX: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/ecapx-count-by-purchaseOrg`,
    PURCHASE_ORG_LIST_HIGH_COUNT_BY_GRA: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/gra-count-by-purchaseOrg`,
    PURCHASE_ORG_LIST_HIGH_COUNT_BY_INVOICE: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/invoice-count-by-purchaseOrg`,
    PURCHASE_ORG_LIST_HIGH_COUNT_BY_PAYMENTREME: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/paymentReme-count-by-purchaseOrg`,
    PURCHASE_ORG_LIST_HIGH_COUNT_BY_CONTRACT: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/contract-count-by-purchaseOrg`,
    PURCHASE_ORG_LIST_HIGH_COUNT_BY_ANNOUNCEMENT: `${GlobalVariable.BASE_URL}/api/buyer/buyer-dashboard/announcement-count-by-purchaseOrg`,

    PAYMENT_TERMS_LIST: `${GlobalVariable.BASE_URL}/api/buyer/master/payment-terms-list`,

    APPROVAL_MANAGEMENT_LIST: `${GlobalVariable.BASE_URL}/api/buyer/approval-management/findAll`,
    APPROVAL_MANAGEMENT: `${GlobalVariable.BASE_URL}/api/buyer/approval-management`,
    APPROVAL_MANAGEMENT_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/approval-management/{0}`,
    APPROVAL_MANAGEMENT_DOWNLOAD_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/approval-management/download-template-excel`,
    APPROVAL_MANAGEMENT_EXPORT: `${GlobalVariable.BASE_URL}/api/buyer/approval-management/export-approval-management-excel`,
    APPROVAL_MANAGEMENT_IMPORT: `${GlobalVariable.BASE_URL}/api/buyer/approval-management/import-approval-management-excel`,

    // GRA
    CREATE_GRA: `${GlobalVariable.BASE_URL}/api/buyer/gra-buyer/create`,
    UPDATE_GRA: `${GlobalVariable.BASE_URL}/api/buyer/gra-buyer/update`,
    GET_GRA_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/gra-buyer/`,
    GET_GRA_REVERSE_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/gra-buyer/gra/reverse/`,
    PO_LIST_FOR_GRA: `${GlobalVariable.BASE_URL}/api/buyer/po/po-list-for-gra/`,
    FETCH_PO_ITEMS_BY_PO_NUMBERS: `${GlobalVariable.BASE_URL}/api/buyer/po/fetch-po-items`,
    DELETE_GRA_DOCS_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/gra-buyer/document`,
    DELETE_GRA_DOCS_BY_NAME: `${GlobalVariable.BASE_URL + DOCUMENTS.DOCUMENT_DELETE}`,
    SUBMIT_GRA_FOR_APPROVAL: `${GlobalVariable.BASE_URL}/api/buyer/gra-buyer/submitForApproval/`,
    CANCEL_GRA: `${GlobalVariable.BASE_URL}/api/buyer/gra-buyer/cancel/`,
    GRA_ERROR_LIST: '/api/buyer/gra-buyer/pending-gra-list',
    TRIGGER_GRA: `${GlobalVariable.BASE_URL}/api/buyer/gra-buyer/triggerGRA/`,
    GET_GRA_APPROVAL_ROUTE: `${GlobalVariable.BASE_URL}/api/buyer/gra-buyer/getApprovalRoute/`,
    GET_GRA_AUDIT_HISTORY: `${GlobalVariable.BASE_URL}/api/buyer/gra-buyer/auditHistory/`,
    GRA_APPROVAL: `${GlobalVariable.BASE_URL}/api/buyer/gra-buyer/graApproval/`,
    GRA_ESCALATE: `${GlobalVariable.BASE_URL}/api/buyer/gra-buyer/escalate/`,
    GRA_REVERSAL: `${GlobalVariable.BASE_URL}/api/buyer/gra-buyer/reversal/`,
    USER_APPROVAL_MANAGEMENT_LIST: `${GlobalVariable.BASE_URL}/user/approval-management-list`,

    //Budget
    ADD_BUDGET: `${GlobalVariable.BASE_URL}/api/buyer/budget-reference/budget-reference/add`,
    DEDUCT_BUDGET: `${GlobalVariable.BASE_URL}/api/buyer/budget-reference/budget-reference/deduct`,
    TRANSFER_OPEX_BUDGET: `${GlobalVariable.BASE_URL}/api/buyer/budget-reference/budget-reference/transfer-opex`,
    OPEX_BUDGET_BALANCE_SUMMARY: `${GlobalVariable.BASE_URL}/api/buyer/budget-reference/budget-balance-summary/byCostCenterAndGLCode`,
    BUDGET_LIST: `${GlobalVariable.BASE_URL}/api/buyer/budget-reference/budget-reference-list`,
    BUDGET_BALANCE_CAPEX_LIST: `${GlobalVariable.BASE_URL}/api/buyer/budget-reference/budget-balance-capex-list`,
    BUDGET_CREATE: `${GlobalVariable.BASE_URL}/api/buyer/budget-reference/create`,
    DELETE_BUDGET_DOCS_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/budget-reference/deleteDocs`,
    GET_BUDGET_BY_ID: `${GlobalVariable.BASE_URL}/api/buyer/budget-reference/budget-balance-summary/`,
    BUDGET_DOWNLOAD_TEMPLATE: `${GlobalVariable.BASE_URL}/api/buyer/budget-reference/download-budget-reference-template`,
    BUDGET_EXPORT: `${GlobalVariable.BASE_URL}/api/buyer/budget-reference/exportExcel`,
    BUDGET_UPLOAD: `${GlobalVariable.BASE_URL}/api/buyer/budget-reference/import-budget-reference-excel`, //NOTE: change endpoint
    BUDGET_TRANSACTION_LOG: `${GlobalVariable.BASE_URL}/api/buyer/budget-reference/download-transaction-log`, //NOTE: change endpoint
    TRANSFER_CAPEX_BUDGET: `${GlobalVariable.BASE_URL}/api/buyer/budget-reference/budget-reference/transfer-capex/`,
    DELETE_BUDGET_DOCUMENT_BY_NAME: `${GlobalVariable.BASE_URL + DOCUMENTS.DOCUMENT_DELETE}`,
    GL_CODE_LIST_BY_COMPANY_CODE: `${GlobalVariable.BASE_URL}/api/buyer/master/gl-code-list/byCompanyCode`,
    COST_CENTER_LIST_BY_COMPANY_CODE: `${GlobalVariable.BASE_URL}/api/buyer/master/cost-center-list/byCompanyCode`,
    DEPARTMENT_LIST_BY_COMPANY_CODE: `${GlobalVariable.BASE_URL}/api/buyer/master/department-list/byCompanyCode`,
    BUDGET_REFERENCE_LIST_DROPDOWN: `${GlobalVariable.BASE_URL}/api/buyer/budget-reference/budget-reference-list-dropdown`,
    BUDGET_REFERENCE_DETAILS: `${GlobalVariable.BASE_URL}/api/buyer/budget-reference/budget-details/`,
    BUDGET_REFERENCE_TRANSACTION_LOG: `${GlobalVariable.BASE_URL}/api/buyer/budget-reference/budget-transaction-log/`,
    BUDGET_REFERENCE_BY_GL_COST_CENTER: `${GlobalVariable.BASE_URL}/api/buyer/budget-reference/budget-reference-details`,
    DISABLE_BUDGET: `${GlobalVariable.BASE_URL}/api/buyer/budget-reference/disable/`,
    BUDGET_PURCHASE_ORG_LIST: `${GlobalVariable.BASE_URL}/user/purchaseOrg-list-by-userId`,
    COUNT_BY_PURCHASE_ORG: `${GlobalVariable.BASE_URL}/api/buyer/budget-reference/count-by-purchasing-org`,

    //DOWNTIME MESSAGE
    DOWNTIME_MESSAGE: `${GlobalVariable.BASE_URL}/api/buyer/downtime-message/check-service-downtime-status`,
});

import { Pipe, PipeTransform } from '@angular/core';
import { continentalTimeToAmPM } from 'app/helper/shared-function';

@Pipe({
  name: 'ampm'
})
export class AmpmPipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): unknown {        
        return continentalTimeToAmPM(value);
    }

}

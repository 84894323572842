import { Input, ElementRef, HostListener, Directive } from '@angular/core';

@Directive({
    selector: '[appOnlyNumber]'
})
export class OnlyNumberDirective {

    @Input('appOnlyNumber') options!: string;

    constructor(public el: ElementRef) { }

    @HostListener('keydown', ['$event'])
    onKeyDown(event: any): boolean {
        const e = event.which || event.keyCode;
        const key = event.key;
        if ((key >= 0 || key <= 9) && key !== ' ') {
            if (this.options) {
                let value = '';
                if (event.target && event.target.value) {
                    value = event.target.value + String(key);
                } else {
                    value = key;
                }
                if (Number(value) <= Number(this.options)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return true;
            }
        }

        if ((e >= 96 && e <= 105) || e === 8 || e === 9) {
            return true;
        } else {
            return false;
        }
    }
}

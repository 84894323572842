import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-multiline-error-snack',
  templateUrl: './multiline-error-snack.component.html',
  styleUrls: ['./multiline-error-snack.component.scss']
})
export class MultilineErrorSnackComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public messages: string[]) { }

  ngOnInit(): void {
  }

}

<mat-dialog-content class="mat-typography">
    <mat-radio-group aria-label="Select an option" class="w-4/12 flex flex-row my-4" [color]="'primary'"
        (change)="selectedViewer= $event.value">
        <mat-radio-button [value]="item.name" *ngFor="let item of viewerType" class="mr-5">
            {{item.name | titlecase}}
        </mat-radio-button>
    </mat-radio-group>

    <div class="float-right">
        <button type="button" mat-button mat-flat-button [disabled]="!selectedViewer"
            class="mat-focus-indicator bg-accent-700 mat-flat-button mat-button-base mat-primary mat-button"
            (click)="onSubmit()">Add
        </button>
    </div>
</mat-dialog-content>
<mat-form-field>
    <mat-checkbox [required]="true" [checked]="initialValue"
        [ngClass]="{'custom-frame' : (control?.touched || control?.dirty) && control?.errors }"
        (change)="checkboxHandler($event)">
        Read Only
    </mat-checkbox>
</mat-form-field>

{{ control.errors | json }}
{{ control.touched }}
<!-- [ngStyle]="{'color': '#ea5455'}"   -->
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Component, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements ControlValueAccessor, OnInit {

    initialValue: boolean
    isFieldDisabled: boolean

    constructor(@Self() @Optional() public control: NgControl) {
        this.control && (this.control.valueAccessor = this);
    }

    updateForm = (value: boolean) => { };
    onTouched = () => { };

    ngOnInit(): void {
    }

    checkboxHandler(event: MatCheckboxChange) {
        this.onTouched()
        this.updateForm(event.checked)
    }

    writeValue(value: boolean): void {
        this.initialValue = value
    }

    registerOnChange(fn: any): void { this.updateForm = fn }
    registerOnTouched(fn: any): void { this.onTouched = fn }

    setDisabledState?(isDisabled: boolean): void {
        this.isFieldDisabled = isDisabled
    }

}
